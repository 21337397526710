import img1 from './ss_000.jpg';
import img2 from './ss_001.jpg';
import img3 from './ss_002.jpg';
import img4 from './ss_003.jpg';
import img5 from './ss_004.jpg';
import img6 from './ss_005.jpg';
import img7 from './ss_006.jpg';
import img8 from './ss_007.jpg';
import img9 from './ss_008.jpg';
import img10 from './ss_009.jpg';
import img11 from './ss_010.jpg';
import img12 from './ss_011.jpg';
import img13 from './ss_012.jpg';
import img14 from './ss_013.jpg';
import img15 from './ss_014.jpg';


export default [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15
];